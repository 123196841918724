import React, { useState, useEffect } from 'react';
import { toPng } from 'html-to-image';
import { Document, Page, Text, View, Image, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';


const StudentProgressPDF = ({pdfReady, setPdfReady, student, currentPeriod, selectedPeriods, bundlessWithIntervention, progressTableRef, progressBarChartRef}) => {

    const [pdfLoading, setPdfLoading] = useState(false);
    const [progressTableImage, setProgressTableImage] = useState();
    const [progressBarChartImage, setProgressBarChartImage] = useState();
    Font.register({ family: 'Poppins', src: 'https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf' });


    const generatePdf = async () => {
        setPdfLoading(true);
        try {
          await captureImages();
          setPdfReady(true); // Only set pdfReady after image capture
        } catch (error) {
          console.error("Error generating PDF: ", error);
        } finally {
          setPdfLoading(false); // Stop loading once done
        }
    }

    useEffect(() => {
        if (pdfReady) {
            setPdfLoading(false);
        }
    }, [pdfReady])

    const captureImages = () => {
        toPng(progressTableRef.current)
            .then((dataUrl) => {
                setProgressTableImage(dataUrl);
            })
            .catch((error) => {
                console.error('Could not capture progress table image', error);
            });

        toPng(progressBarChartRef.current)
            .then((dataUrl) => {
                setProgressBarChartImage(dataUrl);
                console.log('chart image', dataUrl);
            })
            .catch((error) => {
                console.error('Could not capture progress bar chart image', error);
            });
    }
    
    // PDF Styles
    const pdfStyles = StyleSheet.create({
        title: { fontFamily: 'Poppins', fontWeight: 700, color: '#3C5063' },
        text: { fontFamily: 'Poppins', fontWeight: 700, color: '#3C5063', fontSize: 10 },
        textUnderline: { fontFamily: 'Poppins', fontWeight: 700, color: '#3C5063', fontSize: 12, textDecoration: 'underline' },
        page: { flexDirection: 'column', backgroundColor: '#E4E4E4' },
        section: { marginLeft: '30px', marginRight: '30px', paddingTop: '35px', paddingBottom: '25px' },
        image: { objectFit: 'scale-down', paddingTop: 25, paddingRight: 3, paddingBottom: 25, paddingLeft: 3},
        imageCont: { maxHeight: '75vh', maxWidth: '100vw' },
        detailsSection: {paddingLeft: 3, paddingRight: '130px', marginTop: '20px', },
        singleOutcome: { marginTop: 10 },
        logo: { width: 100, position: 'absolute', top: '30px', right: 0 },
        footer: { position: 'absolute', top: '91vh', left: 0,  maxWidth: '100vw' },
        footerText: { fontSize: '8pt', color: '#5c5c5c'}
    });

    // The PDF document component
    const PdfDocument = ({progressTableImage, progressBarChartImage, bundlessWithIntervention}) => (
        <Document>
            <Page size="A4">
                <View style={pdfStyles.section}>
                <Image style={pdfStyles.logo} src={require('../../assets/SCP-logo.jpg')} />

                    <View style={pdfStyles.detailsSection}>
                        <Text style={pdfStyles.title}>{student.name} {currentPeriod && ` - Year ${currentPeriod.year_group}`} {student.school && student.school.name && ` - ${student.school.name}`}</Text>
                        <Text style={pdfStyles.text}>School - {student.school && student.school.name}</Text>
                        <Text style={pdfStyles.text}>Current Intervention lead - 
                            {currentPeriod && ` ${currentPeriod.tutor_group && currentPeriod.tutor_group.tutor && currentPeriod.tutor_group.tutor.name}`}
                        </Text>
                        <Text style={pdfStyles.text}>Reporting Periods - 
                            {selectedPeriods && selectedPeriods[0] && selectedPeriods.map((selectedPeriod, index) => 
                                <Text style={pdfStyles.text}> {selectedPeriod.period_name} {selectedPeriod.calendar_year} {index !== selectedPeriods.length - 1 && ', '}</Text>
                            )}
                        </Text>
                    </View>
                    <View style={pdfStyles.imageCont}>
                        {progressTableImage && <Image style={pdfStyles.image} src={progressTableImage} />}
                    </View>

                    <View style={pdfStyles.footer}>
                        <Text style={pdfStyles.footerText}>School Consultancy Programme is part of Tutor Your Child Ltd (registered 13378546). Assessments results are calculated by analysing the assessment information available to us at the time using our experience and qualifications. This may not represent the views of all agencies or individuals, and should be used in conjunction with other information available.</Text>
                    </View>
                </View>
            </Page>
            <Page size="A4">
                <View style={pdfStyles.section}>
                    <Image style={pdfStyles.logo} src={require('../../assets/SCP-logo.jpg')} />

                    <View style={pdfStyles.imageCont}>
                        {progressBarChartImage && <Image style={pdfStyles.image} src={progressBarChartImage} />}
                    </View>
                    <View>
                        <Text style={pdfStyles.title}>Progress Outcome Statements</Text>
                        <Text style={pdfStyles.text}>Outcomes for bundles completed in period: {currentPeriod.period_name} {currentPeriod.calendar_year}</Text>

                        {bundlessWithIntervention && bundlessWithIntervention.map((bundle) => (
                            <View style={pdfStyles.singleOutcome}>
                                <Text style={pdfStyles.textUnderline}>{bundle.levelLabel} {bundle.label}</Text>
                                <Text style={pdfStyles.text}>{bundle.outcome}</Text>
                            </View>
                        ))}
                    </View>

                    <View style={pdfStyles.footer}>
                        <Text style={pdfStyles.footerText}>School Consultancy Programme is part of Tutor Your Child Ltd (registered 13378546). Assessments results are calculated by analysing the assessment information available to us at the time using our experience and qualifications. This may not represent the views of all agencies or individuals, and should be used in conjunction with other information available.</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            {progressTableImage && progressBarChartImage && pdfReady ? (
                <PDFDownloadLink
                    document={
                        <PdfDocument
                            progressTableImage={progressTableImage}
                            progressBarChartImage={progressBarChartImage}
                            bundlessWithIntervention={bundlessWithIntervention}
                            currentPeriod={currentPeriod}
                        />
                    }
                    fileName="complex_components.pdf"
                    className="mainButton pdfButton"
                >
                    {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'Download PDF')}
                </PDFDownloadLink>
            ) : (
                <button onClick={generatePdf} className="pdfButton" disabled={pdfLoading}>{pdfLoading ? 'Generating PDF...' : 'Generate PDF'}</button>
            )}
        </>
    )
}

export default StudentProgressPDF;